import Excel from "exceljs";
import ExcelBase from "@/apps/core/modules/excelBase";
import { ToastProgrammatic as Toast } from "buefy";

const MONTH_LIST = [
  "januari",
  "februari",
  "maret",
  "april",
  "mei",
  "juni",
  "juli",
  "agustus",
  "september",
  "oktober",
  "november",
  "desember"
];

const PRECEPTOR_COLS = [
  "Nama",
  "Inisial",
  "Email",
  "No Hp",
  "Alamat",
  "Catatan"
];

const PSPD_COLS = [
  "Nama",
  "Inisial",
  "NPM",
  "Kelompok",
  "Angkatan",
  "Email",
  "No Hp",
  "Alamat",
  "Catatan"
];

class AnggotaExcel extends ExcelBase {
  constructor(onSaved, onFinished) {
    super();
    this.angkatanList = [];
    this.departemenList = [];
    this.onSaved = onSaved;
    this.onFinished = onFinished;
  }

  setAngkatanList(angkatanList) {
    this.angkatanList = angkatanList;
  }

  setDepartemenList(departemenList) {
    this.departemenList = departemenList;
  }

  async writeWorkbook() {
    const workbook = new Excel.Workbook();
    const wsPspd = workbook.addWorksheet("Mahasiswa");
    wsPspd.getRow(1).values = PSPD_COLS;
    wsPspd.getColumn("E").width = 15;
    const angkatanValidation = {
        type: 'list',
        allowBlank: false,
        operator: 'between',
        showInputMessage: true,
        formulae: this.angkatanList,
        promptTitle: 'Angkatan',
        showErrorMessage: true,
        error: "Opsi tidak tersedia.",
        prompt: 'Silakan pilih angkatan yang tersedia.'
    };
    for (const idx of Array(50).keys()) {
        wsPspd.getCell(`E${idx + 2}`).dataValidation = angkatanValidation;
    }
    wsPspd.getCell("D1").note = "Jika akan didaftarkan ke beberapa kelompok, pisahkan dengan \",\". Contoh : 4.3, 5.4.";

    const wsPreceptor = workbook.addWorksheet("Dosen");
    wsPreceptor.getRow(1).values = PRECEPTOR_COLS;
    // wsPreceptor.getColumn("C").width = 15;
    // const departemenValidation = {
    //     type: 'list',
    //     allowBlank: false,
    //     operator: 'between',
    //     showInputMessage: true,
    //     formulae: this.departemenList,
    //     promptTitle: 'Departemen',
    //     showErrorMessage: true,
    //     error: "Opsi tidak tersedia.",
    //     prompt: 'Silakan pilih departemen yang tersedia.'
    // };
    // for (const idx of Array(50).keys()) {
    //     wsPreceptor.getCell(`C${idx + 2}`).dataValidation = departemenValidation;
    // }
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", this.filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  async load(event) {
    this.payLoadData = { pspd: [], preceptor: [] };
    let _data = this.payLoadData;
    try {
      const workbook = new Excel.Workbook();
      await workbook.xlsx.load(event.target.result);
      const wsPspd = workbook.getWorksheet("Mahasiswa");
      if (!wsPspd) {
        Toast.open({ message: "Format excel salah. Sheet Mahasiswa tidak ditemukan.", duration: 3000 });
        return;
      }

      wsPspd.eachRow({ includeEmpty: false }, function (row, rowNumber) {
        if (rowNumber > 1) {
          const nama = row.getCell(1).value;
          let inisial = row.getCell(2).value;
          inisial = inisial ? inisial : "";
          const npm = row.getCell(3).value;
          const kelompok = row.getCell(4).value;
          let angkatan = row.getCell(5).value;
          if (angkatan) {
            const year = angkatan.split(" ")[1].replace(/ /g, "");
            let month = angkatan
              .split(" ")[0]
              .toLowerCase()
              .replace(/ /g, "");
            const idx = MONTH_LIST.indexOf(month);
            month = idx != -1 ? idx + 1 : 0;
            angkatan = `${year}-${String(month).padStart(2, "0")}-01 00:00:00`;
          }
          let email = row.getCell(6).value;
          email = email && email.text ? email.text : email;
          let noHp = row.getCell(7).value;
          noHp = noHp ? noHp : "";
          let alamat = row.getCell(8).value;
          alamat = alamat ? alamat : "";
          let catatan = row.getCell(9).value;
          catatan = catatan ? catatan : "";
          let tags = kelompok ? kelompok.toString().replace(/ /g, "").split(",") : [];
          const pspd = {
            nama: nama,
            inisial: inisial,
            npm: npm,
            tag: tags,
            angkatan: angkatan,
            user: { email: email },
            no_hp: noHp,
            alamat: alamat,
            catatan: catatan
          };
          _data["pspd"].push(pspd);
        }
      });
      
      const wsPreceptor = workbook.getWorksheet("Dosen");
      if (!wsPreceptor) {
        Toast.open({ message: "Format excel salah. Sheet Dosen tidak ditemukan.", duration: 3000 });
        return;
      }
      wsPreceptor.eachRow({ includeEmpty: false }, function (row, rowNumber) {
        if (rowNumber > 1) {
          const nama = row.getCell(1).value;
          let inisial = row.getCell(2).value;
          inisial = inisial ? inisial : "";
          let email = row.getCell(3).value;
          email = email && email.text ? email.text : email;
          let noHp = row.getCell(4).value;
          noHp = noHp ? noHp : "";
          let alamat = row.getCell(5).value;
          alamat = alamat ? alamat : "";
          let catatan = row.getCell(6).value;
          catatan = catatan ? catatan : "";
          const preceptor = {
            nama: nama,
            inisial: inisial,
            user: { email: email },
            no_hp: noHp,
            alamat: alamat,
            catatan: catatan
          };
          _data["preceptor"].push(preceptor);
        }
      });
      this.post(this.onSaved, this.onFinished);
    } catch {
      Toast.open("Format excel tidak sesuai.");
    }
  }

  loadMessage(errorMessages) {
    let message = '';
    if (errorMessages.pspd_errors.length > 0) {
      message = '<strong>Data Mahasiswa:</strong>';
      message += '<ul style="list-style: square; padding: 15px;">';
      for (const error of errorMessages.pspd_errors) {
        let errorText = '';
        for (const [key, val] of Object.entries(error.errors)) {
          if (Array.isArray(val)) {
            errorText += `<p>${key}: ${val[0]}</p>`;
          } else {
            for (const [field, msg] of Object.entries(val)) {
              errorText += `<p>${field}: ${msg[0]}</p>`;
            }
          }
        }
        message += `<li :key="${error.email}">Baris ke ${error.row_num} ${error.email} :<br>${errorText}</li>`;
      }
      message += '</ul>';
    }

    if (errorMessages.preceptor_errors.length > 0) {
      message += '<strong>Data Dosen:</strong>';
      message += '<ul style="list-style: square; padding: 15px;">';
      for (const error of errorMessages.preceptor_errors) {
        let errorText = '';
        for (const [key, val] of Object.entries(error.errors)) {
          if (Array.isArray(val)) {
            errorText += `<p>${key}: ${val[0]}</p>`;
          } else {
            for (const [field, msg] of Object.entries(val)) {
              errorText += `<p>${field}: ${msg[0]}</p>`;
            }
          }
        }
        message += `<li :key="${error.email}">Baris ke ${error.row_num} ${error.email} :<br>${errorText}</li>`;
      }
      message += '</ul>';
    }
    return message;
  }
}


export default AnggotaExcel;